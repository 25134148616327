import * as React from 'react';
import { motion } from 'framer-motion';
import Link from 'gatsby-link';
import './product-card.scss';
import ArrowRightIcon from '!svg-react-loader!../../images/vectors/arrow_right.svg';


const ProductCard = ({ productImage: ProductImage, title, description, links }) => {
  return (
    <div className={`product-card-main`}>
      <ProductImage height={125} />
      <div className='product-card-title'>{title}<sup>&reg;</sup></div>
      <div className={`product-description`}>{description}</div>
      <div className={`product-card-links`}>
        {links.map(link => {
          const { linkTo = '/' } = link;
          return (
            <motion.div
              key={link.id}
              whileTap={{ scale: 0.97 }}
              className='product-card-link'
            >
              <Link to={linkTo} className='pc-link-content mt-track-click'>
                {link.title}
                <span className='pc-link-arrow'><ArrowRightIcon /></span>
              </Link>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};


export default ProductCard;
