import * as React from 'react';
import { motion } from 'framer-motion';

import SEO from '../components/seo';
import ArrowUpIcon from '!svg-react-loader!../images/vectors/arrow_up.svg';
import SmartAssistantLogo from '!svg-react-loader!../images/vectors/smart-assistant-logo.svg';
import './index.scss';
import ProductCard from '../components/product-card';


const SmartAssistantPage = () => {
  const description = `Digitize practice. Automate workflows. Minimize operational hassle. Generate higher operating income. Provide end-to-end care to your patients.`;
  const links = [
    { id: 'clinics', title: 'Clinics', linkTo: '/SA-Clinic' },
    { id: 'hospitals', title: 'Hospitals', linkTo: '/SA-Hospital' }
  ];
  return (
    <div>
      <SEO title="Smart Assistant" />
      <div className="container-fluid main-container">
        <ProductCard
          title={'Smart Assistant'}
          productImage={SmartAssistantLogo}
          description={description}
          links={links}
        />
      </div>
      <div className={`page-footer-fixed`}>
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ repeat: Infinity, repeatDelay: 2, duration: 0.4 }}
        >
          <ArrowUpIcon />
        </motion.div>
        <span className={`page-footer-text`}>swipe up for Smart Rep</span>
      </div>
    </div>
  );
};

export default SmartAssistantPage;
